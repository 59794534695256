/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";


.scroll-content {
    padding-bottom: 80px !important;
  }
  
  
  h3 {
    color: #3CC178;
    font-size: 15px !important;
    margin-bottom: 0 !important;
    margin-top: 0.8rem !important;
    font-weight: bold;
  }
  
  a:not([target]) {
    // text-decoration: underline;
    font-weight: bold;
    
    &.no-link, &.no-link:hover {
      color: gray;
      cursor: none;
      opacity: 1 !important;
      // font-weight: normal !important;
    }
  }
  
  
  p {
    margin-top: 5px;
    margin-bottom: 25px;
    font-size: 15px !important;
  }

  a {
      font-size: 14px;
  }
  
  .split-pane-md.split-pane-visible > .split-pane-side {
    max-width: 250px !important;
  }
  
  .buttonAdd {
    position: absolute !important;
    right: 3px;
    margin-top: 0px;
  
    ion-icon {
      font-size: 1.8rem;
    }
  }
  
  .buttonImage {
    position: absolute !important;
    right: 0;
  
    ion-icon {
      font-size: 2.2em;
    }
  }
  
  .item-input .buttonImage {
    margin-top: 15px !important;
  }
  
  .item-textarea .buttonImage {
    margin-top: 35px !important; 
  }
  
  .list-md .item-input:last-child {
    border-bottom: none !important;
  }
  
  .platform-core ion-fab[bottom] {
    bottom: 26px !important;
    right: 40px !important;
  }
  
  .img-container {
    position: relative;
  
    .image-icon {
      position: absolute;
      top: 0px;
      left: 18px;
      color: #fff;
      font-size: 1em;
      background: red;
      border-radius: 50%;
    }
  }
  
